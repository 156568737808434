import React from 'react';
import { navigate } from 'gatsby';
import '../i18n';

const Main = () => {
  React.useEffect(() => {
    navigate('/dashboard');
  });
  return null;
};
export default Main;
